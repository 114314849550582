@tailwind base;
@tailwind components;
@tailwind utilities;

@import "leaflet/dist/leaflet.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";

@import url("https://unpkg.com/leaflet@1.7.1/dist/leaflet.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: arial, helvetica, sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#root {
  display: grid;
  grid-template-rows: 12% 4fr 1fr;
  height: 100vh;
}

@media (min-width: 768px) {
  #root {
    grid-template-rows: 24.2% 4fr 1fr;
  }
}

/* header */

#header {
  background-color: #ffffff;
  z-index: 120;
}

.menu__item:hover {
  border-bottom: 2px solid #fff;
}

.submenu {
  padding: 20px 0;
  transform: translateY(-200px);
  display: none;
  transition: transform 0.8s ease-in-out, display 0.8s ease-in-out;
}

.submenu.menu__actif {
  position: relative;
  display: block;
  transform: translateY(0);
  animation: slideDown 0.8s ease-in-out forwards;
}

.submenu.menu__inactif {
  animation: slideUp 0.8s ease-in-out forwards;
  animation-fill-mode: forwards;
}

.submenu_items:hover {
  text-decoration: underline;
}

@keyframes slideDown {
  from {
    transform: translateY(-200px);
    display: none;
  }
  to {
    transform: translateY(0);
    display: block;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    display: block;
  }
  to {
    transform: translateY(-200px);
    display: none;
  }
}

/* home slider */

.carousel__text {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.408));
}

@media (min-width: 768px) {
  .carousel__text {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.973) 25%,
      rgba(255, 255, 255, 0.865) 75%,
      rgba(255, 255, 255, 0.147) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.carousel img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease-in-out;
}

.carousel img.transform.translate-x-0 {
  transform: translateX(0);
}

.carousel img.transform.translate-x-full {
  transform: translateX(100%);
}

.carousel .next__button {
  opacity: 0;
}

.carousel:hover .next__button {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}

/* footer */

.foote__item:hover {
  text-decoration: underline;
}

.footer__link:hover {
  text-decoration: underline;
}

/* notre mission */

.notremission__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 640px) {
  .notremission__items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .notremission__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* groups */

.group {
  height: 70vh;
  width: 100%;
  background: url("/src/public/images/appli.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

@media (min-width: 768px) {
  .group {
    height: 45vh;
  }
}

/* joindus */

.joindus {
  background: linear-gradient(
    90deg,
    rgba(220, 219, 217, 0.933) 0%,
    rgba(220, 219, 217, 0.881) 25%,
    rgba(220, 219, 217, 0.46) 75%,
    rgba(220, 219, 217, 0.217) 90%,
    rgba(220, 219, 217, 0.031) 100%
  );
}

.ed {
  background: linear-gradient(
    90deg,
    rgba(252, 252, 252, 0.807) 0%,
    rgba(255, 255, 255, 0.668) 25%,
    rgba(255, 255, 255, 0.031) 60%
  );
}

.network__youtube,
.network__facebook,
.network__twitter,
.network__linkedin {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.network__youtube {
  background: linear-gradient(rgba(170, 5, 5, 0.926), rgba(128, 7, 7, 0.926));
}

.network__facebook {
  background: linear-gradient(rgba(5, 88, 170, 0.986), rgba(7, 68, 128, 0.926));
}

.network__twitter {
  background: linear-gradient(
    rgba(16, 97, 178, 0.986),
    rgba(6, 67, 128, 0.926)
  );
}

.network__linkedin {
  background: linear-gradient(
    rgba(16, 97, 178, 0.986),
    rgba(6, 67, 128, 0.926)
  );
}

@media (min-width: 768px) {
  .network__youtube,
  .network__facebook,
  .network__twitter,
  .network__linkedin {
    width: 50px;
    height: 50px;
  }
}

/* accordions */

.accordions {
  transform: skewX(10deg);
}

.accordions:hover {
  transform: skewX(0deg);
  transition: all 0.5s ease-in-out;
}
.accordions.active {
  transform: skewX(0deg);
}

/* Rapport  */
.resume ul {
  list-style: none;
  padding: 0;
}

.resume ul li {
  position: relative;
  margin-left: 30px;
}

.resume ul li::before {
  content: "•";
  position: absolute;
  left: -20px;
  color: #ff0000;
  font-size: 1.2em;
}

/* map */
.map-scroll {
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 70vh;
}
/* page header */
.pageHeader {
  clip-path: polygon(14% 50%, 93% 80%, 96% 100%, 0 100%, 0 0);
}

@media (min-width: 768px) {
  .pageHeader {
    clip-path: polygon(14% 63%, 93% 70%, 96% 100%, 0 100%, 0 0);
  }
}
/* blog detail */

.bogs_details a {
  color: rgb(32, 52, 163);
}

/*  */
.react-tel-input {
  width: 100%;
  display: flex;
}

input[type="tel"] {
  padding: 22px;
  flex: 1;
  border: 1px solid #e8e7e7;
  border-radius: 5px;
  width: 100%;
}

.phone-error {
  border: 1px solid #ec0b0b;
}

.react-pdf__Page__canvas {
  width: 100% !important;
}
